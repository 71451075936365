/* -- Core -- */
import { memo } from 'react';
import PropTypes from 'prop-types';

/* -- Design -- */
import { Badge } from '@mui/material';

import { amber, green, grey, red } from '@mui/material/colors';
import { keyframes } from '@mui/system';

const pullseAnimation = keyframes`
  0% {
    transform: scale(.8);
    opacity: 1;
  },
  20% {
    opacity: 1;
  },
  100% {
    transform: scale(2.4);
    opacity: 0;
  },
`;

// Exclude classes from props
const ActivityBadge = (props) => {
  return (
    <Badge
      overlap="circular"
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      variant="dot"
      sx={[
        {
          '& .MuiBadge-badge': {
            backgroundColor: grey[500],
            color: grey[500],
            boxShadow: (theme) => `0 0 0 3px ${theme.palette.background.paper}`
          }
        },
        props.active && {
          '& .MuiBadge-badge::after': {
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            borderRadius: '50%',
            animation: `${pullseAnimation} 1.2s infinite ease-in-out`,
            border: '1px solid currentColor',
            content: '""'
          }
        },
        props.color === 'green' && {
          '& .MuiBadge-badge': {
            backgroundColor: green[500],
            color: green[500]
          }
        },
        props.color === 'red' && {
          '& .MuiBadge-badge': {
            backgroundColor: red[500],
            color: red[500]
          }
        },
        props.color === 'orange' && {
          '& .MuiBadge-badge': {
            backgroundColor: amber[500],
            color: amber[500]
          }
        }
      ]}
    >
      {props.children}
    </Badge>
  );
};

ActivityBadge.propTypes = {
  active: false
};

ActivityBadge.propTypes = {
  children: PropTypes.node,
  color: PropTypes.string,
  active: PropTypes.bool
};

export default memo(ActivityBadge);
