/* -- Core -- */
import PropTypes from 'prop-types';

/* -- Design -- */
import { Box } from '@mui/material';
import { keyframes } from '@mui/system';

const spinAnimation = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

function ValkyrieIcon({ className, rotate = 0, spin = false, icon, flip = false, ...props }) {
  return(
    <Box
      {...props}
      as="span"
      sx={[
        {
          height: '1em',
          width: '1.25em',
          lineHeight: 1,
          boxSizing: 'content-box',
          display: 'inline-block',
          position: 'relative',
          overflow: 'visible',
          verticalAlign: 0,
          transition: 'all .2s ease-in-out',
          transform: `rotate(${rotate}deg)`
        },
        spin && {
          'svg': {
            animation: `${spinAnimation} 2s infinite linear`
          }
        },
        flip && {
          transform: flip === 'x' ? 'scaleX(-1)' : (flip === 'x' ? 'scaleY(-1)' : 'scale(-1)')
        }
      ]}
      className={className}
      // eslint-disable-next-line react/no-danger
      dangerouslySetInnerHTML={{ __html: icon.data }}
    />
  );
}

ValkyrieIcon.propTypes = {
  className: PropTypes.string,
  rotate: PropTypes.number,
  spin: PropTypes.bool,
  icon: PropTypes.object,
  flip: PropTypes.oneOf([true, false, 'x', 'y'])
};

export default ValkyrieIcon;
